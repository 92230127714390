import React from "react";

import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import image1 from "./assets/Construction1.png";
import image2 from "./assets/Construction2.png";
import image3 from "./assets/Construction3.png";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Err404 />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

function Err404() {
  return (
    <div className="Main">
      <div className="Err404">
        <div className="ErrCaption">
          <h1>Oops..!</h1>
          <h3>Pioneer Plastics Manufacturing LLC.</h3>
          <p>
            We are developing our website right now.. It will be available soon!
            You can visit us after sometime till then Good Luck!
          </p>
        </div>
        <div className="Images">
          <img src={image1} alt="Under Development" />
          <img className="warning" src={image2} alt="Under Development" />
          <img src={image3} alt="Under Development" />
        </div>
      </div>
    </div>
  );
}
